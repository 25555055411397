import React from 'react';
import { ClipboardListIcon, ChartBarIcon, ClockIcon, SearchIcon } from '@heroicons/react/outline';

const features = [
    {
        title: "Statistics and Aggregated Reports",
        description: "Comprehensive statistics and aggregated reports on resource utilization and project progress.",
        icon: ChartBarIcon,
    },
    {
        title: "AI-Generated Insights",
        description: "Crucial AI-generated insights, enabling higher management to make informed decisions.",
        icon: ClipboardListIcon,
    },
    {
        title: "Activity Log",
        description: "Stores all updates provided by field engineers, organized by date.",
        icon: ClockIcon,
    },
    {
        title: "Historical Analysis",
        description: "Facilitates easy retrieval and analysis of past data to identify issues and understand root causes.",
        icon: SearchIcon,
    },
];

const KeyFeatures = () => {
    return (
        <div className="bg-gray-50 py-16">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center font-helvetica">Key Features</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                    {features.map((feature, index) => (
                        <FeatureCard key={index} feature={feature} />
                    ))}
                </div>
            </div>
        </div>
    );
}

const FeatureCard = ({ feature }) => {
    return (
        <div className="bg-white rounded-lg shadow-sm p-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-md">
            <div className="flex items-center mb-4">
                <feature.icon className="h-8 w-8 text-blue-500 mr-3" />
                <h3 className="text-xl font-semibold text-gray-900 font-helvetica">{feature.title}</h3>
            </div>
            <p className="text-gray-600 font-helvetica">{feature.description}</p>
        </div>
    );
}

export default KeyFeatures;