import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const VisitorCount = () => {
  const [count, setCount] = useState(null);

  useEffect(() => {
    const fetchVisitorCount = async () => {
      const { count, error } = await supabase
        .from('website_visitor')
        .select('*', { count: 'exact' });

      if (error) {
        console.error('Error fetching visitor count:', error);
      } else {
        setCount(count);
      }
    };

    fetchVisitorCount();
  }, []);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Unique Visitors</h1>
        {count !== null ? (
          <p className="text-6xl font-bold">{count}</p>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default VisitorCount;