import React from 'react';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';

const contactInfo = [
    {
        name: "Rishi Venkat",
        email: "rishi.venkat94@gmail.com",
        phone: "+91 9176227255"
    },
    {
        name: "Karthik Venkatesh",
        email: "kv771@nyu.edu",
        phone: "+91 9087789417"
    },
    {
        name: "Ranjith Vyas",
        email: "ranjithramkii@gmail.com",
        phone: "+91 9841469005"
    }
];

const Contact = () => {
    return (
        <div className="bg-gray-50 py-16">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center font-helvetica">Contact Us</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {contactInfo.map((contact, index) => (
                        <ContactCard key={index} contact={contact} />
                    ))}
                </div>
            </div>
        </div>
    );
}

const ContactCard = ({ contact }) => {
    return (
        <div className="bg-white rounded-lg shadow-sm p-6 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-md">
            <h3 className="text-xl font-semibold text-gray-900 mb-4 font-helvetica">{contact.name}</h3>
            <div className="flex items-center mb-2">
                <MailIcon className="h-5 w-5 text-blue-500 mr-2" />
                <a href={`mailto:${contact.email}`} className="text-gray-600 hover:text-blue-500 transition duration-300 ease-in-out">
                    {contact.email}
                </a>
            </div>
            <div className="flex items-center">
                <PhoneIcon className="h-5 w-5 text-blue-500 mr-2" />
                <span className="text-gray-600">{contact.phone}</span>
            </div>
        </div>
    );
}

export default Contact;