import React from 'react';

const ProductDemo = () => {
  return (
    <div className="bg-white py-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 font-helvetica">
        <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Our Product in Action</h2>
        <div className="max-w-xl mx-auto my-auto">
          <video 
            className="w-full rounded-lg shadow-lg max-h-[600px]"
            controls
            poster="/poster-image.png"
          >
            <source src="https://www.instanttransmission.xyz/Instant%20Transmission%20Final-%2022.7.24.MP4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default ProductDemo;