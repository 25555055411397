import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

const ProblemStatement = () => {
    return (
        <div className="bg-gray-50 py-16">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 font-helvetica">
                <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
                    Pain Points in Construction Management
                </h2>
                <div className="space-y-4">
                    <ChallengeCard
                        title="Communication Challenges"
                        icon="📡"
                        items={[
                            {
                                title: "Update Process is Laborious",
                                description: "Providing updates via email, WhatsApp, or ERP system forms is time-consuming and not user-friendly."
                            },
                            {
                                title: "Time Constraints",
                                description: "Site engineers often lack the time to relay information promptly, in a detailed and analytical manner."
                            },
                            {
                                title: "Lack of Structure",
                                description: "Engineers must determine what information to relay for each update, leading to inconsistency in the structure of field reports."
                            },
                            {
                                title: "Communication Skills",
                                description: "Fear of miscommunication can hinder effective reporting."
                            }
                        ]}
                    />
                    <ChallengeCard
                        title="Impact of Inadequate Field Data"
                        icon="📊"
                        items={[
                            {
                                title: "Unforeseen Overheads",
                                description: "Unexpected costs arise due to lack of timely information."
                            },
                            {
                                title: "Time Delays",
                                description: "Projects fall behind schedule due to inefficient communication."
                            },
                            {
                                title: "Lack of Accountability",
                                description: "Insufficient data leads to wastage and unclear responsibilities."
                            },
                            {
                                title: "Planning Inaccuracies",
                                description: "Incomplete information results in flawed project planning and execution."
                            }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

const ChallengeCard = ({ title, icon, items }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <button
                className="w-full p-6 text-left focus:outline-none"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <span className="text-2xl mr-4">{icon}</span>
                        <h3 className="text-xl font-semibold text-black-600">{title}</h3>
                    </div>
                    {isExpanded ? (
                        <ChevronUpIcon className="h-6 w-6 text-black-600" />
                    ) : (
                        <ChevronDownIcon className="h-6 w-6 text-black-600" />
                    )}
                </div>
            </button>
            {isExpanded && (
                <div className="px-6 pb-6">
                    <ul className="space-y-4">
                        {items.map((item, index) => (
                            <li key={index} className="flex flex-col">
                                <span className="text-gray-800 font-semibold">{item.title}</span>
                                <span className="text-gray-600 text-sm">{item.description}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ProblemStatement;