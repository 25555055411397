import React from 'react';
import { CogIcon, ChartBarIcon } from '@heroicons/react/outline';

const BriefExplainer = () => {
    return (
        <div className="bg-white py-16">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-bold text-gray-900 mb-8 font-helvetica text-center">
                    Transforming Construction Management
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <ExplainerCard
                        title="Designed for Indian Construction"
                        description="Our tools are designed to be easy to use and understand, and are tailored to the unique needs of the Indian construction industry."
                        icon={CogIcon}
                    />
                    <ExplainerCard
                        title="AI-Powered Analytics"
                        description="We build AI tools that transform raw voice-based updates from the field into actionable insights, enabling precise tracking of construction operations."
                        icon={ChartBarIcon}
                    />
                </div>
            </div>
        </div>
    );
};

const ExplainerCard = ({ title, description, icon: Icon }) => {
    return (
        <div className="bg-blue-50 p-6 rounded-lg shadow-sm">
            <div className="flex items-center mb-3">
                <Icon className="h-6 w-6 text-blue-500 mr-2" />
                <h3 className="text-xl font-semibold text-gray-800 font-helvetica">{title}</h3>
            </div>
            <p className="text-gray-700 font-helvetica">{description}</p>
        </div>
    );
};

export default BriefExplainer;