import BriefExplainer from './components/BriefExplainer';
import ProblemStatement from './components/ProblemStatement';
import Solution from './components/Solution';
import Contact from './components/Contact';
import KeyFeatures from './components/KeyFeatures';
import Hero from './components/Hero';
import UniqueVisitorCounter from './components/UniqueVisitorCounter';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DisplayVisitorCount from './components/DisplayVisitorCount';
import ProductDemo from './components/ProductDemo';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/secret/the-nights-watch" element={<DisplayVisitorCount />} />
          <Route path="/" element={
            <>
              <UniqueVisitorCounter />
              <Hero />
              <BriefExplainer />
              <ProductDemo />
              <ProblemStatement />
              <Solution />
              <KeyFeatures />
              <Contact />
              <footer className="bg-gray-100 py-4">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                  <p className="text-center text-gray-600 text-sm font-helvetica">
                    © 2024 Aesir Labs, Inc. All rights reserved.
                  </p>
                </div>
              </footer>
            </>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;