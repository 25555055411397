import React from 'react';

const approachContent = [
    {
        title: "Field Data Collection",
        description: "Engineers speak directly to our AI-powered app on-site.",
        details: "Our speech-to-text AI application is tailored for site engineers, allowing them to provide updates effortlessly through voice input."
    },
    {
        title: "AI Processing",
        description: "Spoken inputs are converted into structured reports.",
        details: "The AI processes unstructured voice inputs and generates organized, objective reports within minutes."
    },
    {
        title: "Automated Distribution",
        description: "Reports are instantly shared across the organization.",
        details: "Processed reports are automatically distributed to relevant stakeholders, ensuring timely information flow."
    },
    {
        title: "Management Interface",
        description: "Provides a data-driven overview of field operations.",
        details: "Management can access a comprehensive dashboard, gaining insights into construction processes and archived data for future reference."
    }
];

const OurApproach = () => {
    return (
        <div className="bg-gray-50 py-16">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 font-helvetica">
                <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Our Approach</h2>
                <div className="relative">
                    {approachContent.map((step, index) => (
                        <TimelineStep key={index} step={step} index={index} />
                    ))}
                    <div className="absolute left-8 top-0 h-full w-0.5 bg-gray-400" />
                </div>
            </div>
        </div>
    );
};

const TimelineStep = ({ step, index }) => {
    return (
        <div className="relative mb-8 group">
            <div className="flex items-center mb-2">
                <div className="absolute left-8 -ml-3 h-6 w-6 rounded-full bg-gray-600 shadow-md z-10" />
                <h3 className="text-xl font-semibold text-gray-800 ml-16">{step.title}</h3>
            </div>
            <div className="ml-16 pl-8 border-l border-gray-300">
                <p className="text-gray-700">{step.description}</p>
                <div className="mt-2 p-4 bg-white rounded-lg shadow-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                    <p className="text-gray-600 text-sm">{step.details}</p>
                </div>
            </div>
        </div>
    );
};

export default OurApproach;