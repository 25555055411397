import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createClient } from '@supabase/supabase-js';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const generateVisitorId = () => {
  return uuidv4();
};

const getCurrentDate = () => {
  return new Date().toISOString().split('T')[0];
};

const hashVisitorData = (visitorId, date) => {
  return btoa(`${visitorId}:${date}`);
};

const UniqueVisitorCounter = () => {
  useEffect(() => {
    const handleVisitor = async () => {
      let visitorId = localStorage.getItem('visitor_id');
      let lastVisitDate = localStorage.getItem('last_visit_date');
      const currentDate = getCurrentDate();

      if (!visitorId) {
        visitorId = generateVisitorId();
        localStorage.setItem('visitor_id', visitorId);
      }

      if (lastVisitDate !== currentDate) {
        localStorage.setItem('last_visit_date', currentDate);
        const hashedData = hashVisitorData(visitorId, currentDate);

        try {
          await supabase
            .from('website_visitor')
            .insert({ hash_of_id_and_date: hashedData });
        } catch (error) {
          console.error('Error inserting visitor data:', error);
        }
      }
    };

    const handleScroll = () => {
      handleVisitor();
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return null;
};

export default UniqueVisitorCounter;