import React from 'react';

const Hero = () => {
    return (
        <div className="bg-gradient-to-r from-blue-50 to-white min-h-screen flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl text-center">
                <h1 className="text-4xl sm:text-5xl font-bold text-blue-600 mb-4 font-helvetica">
                    Instant Transmission
                </h1>
                <h2 className="text-2xl sm:text-3xl font-semibold text-gray-800 mb-6 font-helvetica">
                    AI is here to automate your construction reporting. Faster, smarter, and better.
                </h2>
                <p className="text-xl text-gray-600 mb-8 font-helvetica">
                    Turn voice-based updates from the field into highly detailed construction reports.
                    Empower construction managers with AI-driven analysis and insights.
                </p>
                <a 
                    href="https://k86woi8fkbf.typeform.com/to/XBfm6IM9"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm bg-green-600 hover:bg-green-700 border-black border-0 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 font-helvetica inline-block"
                >
                    Sign Up for Early Access
                </a>
            </div>
        </div>
    );
};

export default Hero;